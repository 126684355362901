import React, { useEffect, useContext,useState } from 'react';
import { externalComponent } from '../AppRoutes';
import { api } from '../../services/api';
import Box from '@mui/material/Box';
import ColorButton from '../Buttons/ColorButton';
import toast from 'react-hot-toast';
import { RepresentativeContext } from './distributionR';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { sugestionParser, aplicateSugestions } from './functionsCore';
import DefaultModal from '../Utils/DefaultModal';
import ModalText from './modalText';

const SugestionRepresentative = ({hash}) => {

    const { setLoader } = useContext(externalComponent)
    const {state,setState} = useContext(RepresentativeContext)
    const [modalReset,setModalReset] = useState(false)

    const resetSugestions = () => {
       
        setLoader(true)
            const reset = JSON.parse(state.dataToReset)
            setState({data:reset})
            setState({sugestions:{}})
            setModalReset(false)
            setState({changedTables:!state.changedTables,bksChange:!state.bksChange})
            
        setLoader(false)
    }

    const postSugestions = async () =>  {
          const userId = sessionStorage.getItem("user_id");

          const body = {
            "sendFrom":userId,
            "hash":hash,
            "sugestions":state.sugestions
          }
        
          setLoader(true)
            const res = await api.post('/sugestao/',body)
            if (res.status === 200){
                toast.success('Sugestão enviada com sucesso !')
            }
          setLoader(false)


    }
    
   const getSugestions = async () => {
       
        const res = await api.get(`/sugestao/${hash}/`)
        setLoader(false)
        if (res.status === 200) {
            const data = res.data.sugestions
            Object.values(state.sugestions).map(item => Object.entries(item).map(([key,value]) => {
                const tableNum = key.replace('table','')
                aplicateSugestions(tableNum,value,state.data)
            }))
            setState({data:state.data})
            setState({changedTables:!state.changedTables})
            if(data.length === 0){
                setState({sugestions:{}})
            }else{
                setState({sugestions:data})
            }
            
            return
        }
   }

   useEffect(() => {
    setLoader(true)
    getSugestions()
  },[state.alertSugestion])

  const ButtonConfirmReset = () => {
    return(
        <Box>
            <ColorButton  className='realpadding' onClick={resetSugestions}>Sim</ColorButton>
        </Box>
    )
    }

    const openResetModal = () => {
        setModalReset(true)
    }

    const closeResetModal = () => {
        setModalReset(false)
    }

    return(
        <>

<DefaultModal dialogProps={{maxWidth:"xs"}} open={modalReset} handleClose={closeResetModal} 
                  title={' Confirmação '} content={<ModalText text={'Tem certeza que deseja resetar as sugestões ?'}/>} action={<ButtonConfirmReset/>} />

        <br/>
        <Box sx={{display:'flex',justifyContent:'row'}}>
                <ColorButton sx={{marginRight:'1rem'}} onClick={openResetModal}>Resetar sugestões</ColorButton> 

                <ColorButton onClick={postSugestions}>Salvar sugestões</ColorButton> 
        </Box>

        <List
            sx={{ width: Object.keys(state.sugestions).length !== 0 ? '100%' : '0%', maxWidth: '52rem', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                
            Object.keys(state.sugestions).length !== 0 ?
                <ListSubheader component="div" id="nested-list-subheader">
                    Sugestões:
                </ListSubheader>
            :
            <></>
            }
            >
            {
            Object.keys(state.sugestions).length !== 0 ?

                Object.values(state.sugestions).map(item => Object.entries(item).map(([key,value]) => {

                const tableNum = key.replace('table','')

                return (
                    <ListItemButton key={tableNum}>
                        <ListItemIcon>
                        <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={sugestionParser(tableNum,value)} />
                    </ListItemButton>
                );
                
                }))

            :
            <></>
            }
          </List>
        </>
    )

}

export default SugestionRepresentative;