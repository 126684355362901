import React from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import SendBoxSearch from './sendboxSearch';

const CodproModal = ({data,state}) => {
    
    const columns = [

        {
            field: 'codpro',
            headerName: 'Codpro',
            width:85,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 13px',fontWeight:'bold'}}>
                {value}
              </Box>
            )
        },
        {
          field: 'medida',
          headerName: 'Medida',
          width:85,
          renderCell: ({value}) => (
            <Box sx={{padding:'0px 13px',fontWeight:'bold'}}>
             {value}
            </Box>
          )
        },
        {
            field: 'aro',
            headerName: 'Aro',
            width:100,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 20px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'descr',
            headerName: 'Descrição',
            width:500,
            renderCell: ({value}) => (
              <Box sx={{fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
          field: `est${state.branch}`,
          headerName: `Est ${state.branch.toUpperCase()}`,
          width:85,
          renderCell: ({value}) => (
            <Box className='est-tables' sx={{padding:'0px 20px',fontWeight:'bold'}}>
             {value}
            </Box>
          )
        },
        {
          field: `reserv${state.branch}`,
          headerName: `Reserv ${state.branch.toUpperCase()}`,
          width:85,
          renderCell: ({value}) => (
            <Box className='ven-tables' sx={{padding:'0px 27px',fontWeight:'bold'}}>
             {value}
            </Box>
          )
        },
        {
          field: `dispbks`,
          headerName: `BKS Disp`,
          width:85,
          renderCell: ({value}) => (
            <Box className='dep-bks' sx={{padding:'0px 20px',fontWeight:'bold'}}>
             {value}
            </Box>
          )
      },
      {
        field: 'cargo',
        headerName: 'Envio',
        width:90,
        renderCell: (params) => (
            <Box sx={{display:'flex', justifyContent:'center',width:'100%'}}>
              <SendBoxSearch row={params.row}/>
            </Box>
      )}

    ]

    return(
    <>
        <Box sx={{width:'100%'}} >
            <br/>
                <DataGrid
                        rowHeight={30}
                        className='alterned_grid'
                        density='compact'
                        rows={data}
                        columns={columns}
                        sx={{
                            maxHeight:"30rem",
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                              justifyContent: 'center',
                            }
                        }}
                        
                    /> 
            </Box>
    </>
    )
}

export default CodproModal;