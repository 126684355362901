import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import { RepresentativeContext } from './distributionR';
import { altercam, stringToInt } from './functionsCore';

const SendBoxRepresentative = ({envio,table,codpro,cargo,row}) => {

    const {state,setState} = useContext(RepresentativeContext)

    const [icon,setIcon] = useState(true)
    const [send,setSend] = useState(envio)
    const [lockCargo,setLockCargo] = useState(envio)
  
    const getUpdatedSend = () => {
        
        for (const each of state.data){
            if(each[table]){
                try {
                    const lines = each[table]['lines']
                    const cargo = lines.filter(each => each.codpro === codpro)[0]['cargo']
                    setSend(cargo)
                  } catch (error) {
                    
                  }
            }
        }
    }

    useEffect(() => {
        getUpdatedSend()
    },[state.changedTables])

    const alterDictLines = () => {
        for ( const each of state.data ){
        
            if(each[table]){
              const lines =  each[table]['lines']
              for (const line of lines){
                if(line['codpro'] === codpro){
                    line['cargo'] = stringToInt(send)
                }
              }
    
            }
          }
          setState({state:state.data})
    }

    const createAlterSugestion = () => {

        const rowAux = Object.fromEntries(Object.entries(row));
        rowAux.cargo = cargo
        if(cargo !== send){
            altercam(state.sugestions,codpro,send,table,rowAux)
            alterDictLines()
        }
        
    }

    const removeSugestionRedundant = (send,lock) => {
        if (state.sugestions[codpro]) {
            
            if(state.sugestions[codpro][table].check){

                delete state.sugestions[codpro][table].alter;
                state.sugestions[codpro][table].type = "check"

            }
            else if(state.sugestions[codpro][table].add){
                delete state.sugestions[codpro][table].alter;
                state.sugestions[codpro][table].type = "add"
            }
            else{

                if(send === lock ){
                    delete state.sugestions[codpro][table];
                }

            }
        
            if (Object.keys(state.sugestions[codpro]).length === 0) {
                delete state.sugestions[codpro];
            }
        }
        alterDictLines()
    }

    const changeToInput = () => {
        setIcon(false)
    }

    const isNumber = (value) => {
        return !isNaN(value - 0) && isFinite(value);
    }

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const check_num = isNumber(inputValue)
        if(check_num){
            setSend(inputValue)
        }
      };

    const changeDict = () => {
        let justAdd = true
        //confere o valor original da row da criacao do pedido
        let lock = lockCargo
        if (state.sugestions[codpro]) {
            lock = state.sugestions[codpro][table].row.cargo
            setLockCargo(lock)

            
            if(state.sugestions[codpro][table].add){
                state.sugestions[codpro][table].add = parseInt(send)
                state.sugestions[codpro][table].row.cargo = parseInt(send)
                justAdd = false
            }

        }

        
       
        if(justAdd){
            if(parseInt(lock) !== parseInt(send)){
                createAlterSugestion() 
            }else{
                try {
                    removeSugestionRedundant(parseInt(send),lock)
                } catch (error) {
                    setState({bksChange:!state.bksChange})
                }
                
            }
        }
        
        setIcon(true)
        setState({bksChange:!state.bksChange})
        
      }

    return(
        <>
        {
            icon ?
            <Box onClick={changeToInput} className='pencil-icon'>
                {send}
                <CreateIcon/>
            </Box>
            :
            <Box>
                <input className='input-inside' autoFocus={true} value={send} onChange={handleChange} onBlur={changeDict} type="text"/>
            </Box>
        }   
        </>
    
    )
}

export default SendBoxRepresentative;